.frame-img-login{
    display: inline-block;
    width: 360px;
    height: auto;
}

.frame-img-login .img-login{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.frame-login-form{
    margin-left: auto;
    width: 450px;
    height: 100%;
}

.custom-input-login{
    border-radius: 4px;
    height: 38px;
}

.login-action{
    /* margin-top: 12px; */
    height: 40px;
    width: 100%;
    background-color: #272D3D;
}

.login-action:hover{
    background-color: #333752;
}

.signin-gf-button{
    padding: 4px 12px;
    cursor: pointer;
    width: 100%;
    transition: ease-in-out 0.3s;
    height: 40px;
}

.signin-gf-button.google{
    background-color: rgb(156, 57, 57);
}

.signin-gf-button.google:hover{
    background-color: rgb(180, 75, 75);
}

.signin-gf-button.facebook{
    background-color: rgb(42, 85, 121);
}

.signin-gf-button.facebook:hover{
    background-color: rgb(49, 100, 141);
}

@media (max-width: 849px){
    .frame-login-form{
        width: 380px;
    }
    .grid-col-2{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 578px){
    .frame-img-login{
        display: none !important;
    }
    .frame-login-form{
        margin-left: 0;
        width: 100%;
    }
}