.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* min-height: 100vh; */
}

.container.dark{
  background-color: #06080F;
}

.container.light{
  background-color: white;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1200px;
  /* padding: 32px; */
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.ant-drawer {
  margin-top: 90px;
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.flex-row-page {
  display: flex;
  flex-direction: row;
  margin-top: 97px;
}

.frame-load {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.308);
  height: 100%;
}

.action {
  width: fit-content;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  background-color: #3554d1;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  gap: 8px;
}

.between {
  justify-content: space-between;
}

.left-auto {
  margin-left: auto;
}

.text-blur {
  color: #697488;
}

.flex-wap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.text-left {
  text-align: left;
}

.flex-start {
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.flex-end {
  align-items: flex-end;
  justify-content: flex-end;
}

.status-action {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 100px;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: 718px) {
  .text-search {
    display: none;
  }
}

@media (max-width: 490px) {
  .text-search {
    display: block;
  }
}

@media (max-width: 700px){
  .main{
    padding: 12px;
  }

  .grid-template-home{
    gap: 12px;
  }
}