.header-class{
    width: 100%;
    /* max-width: 2200px; */
    margin: 0 auto;
    background-color: #101628;
    position: fixed;
    top: 0;
    padding: 12px;
    z-index: 10;
}

.logo{
    width: 66px;
    height: 66px;
    object-fit: contain;
}

.flex-end{
    justify-content: flex-end !important;
}

.search-icon{
    padding: 8px 8px 8px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    border-radius: 2px;
    background-color: rgb(32, 40, 66);
    height: 26px;
}

.count-cart{
    width: 20px;
    height: 20px;
    position: absolute;
    top: -15px;
    left: 12px;
    background-color: rgba(127, 238, 195, 0.466);
    display: flex;
    font-size: 12px;
    color: white;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
}

.custom-input{
    background-color: #272D3D;
}

.dropdown-user{
    transition: ease-in-out 0.3s;
    width: 180px;
    background-color: #2b3244;
}

.dropdown-user:hover{
    background-color: #333752;
    color: #FAB55A !important;
}

.icon-menu{
    font-size: 32px;
    cursor: pointer;
    color: #FAB55A;
    display: none;
    margin-right: 52px;
}

.drawer-container{
    background-color: #101628 !important;
}

.drawer-container .ant-drawer-wrapper-body .ant-drawer-header{
    border-bottom: 1px solid #dddddd4b;
}

.header-class .component-container{
    max-width: 1100px;
}

@media (max-width: 1255px){
    .header-class .component-container .flex-col .flex-row .custom-input{
        display: none !important;
    }
    .list-links{
        display: none !important;
    }
    .list-links.responsive{
        display: flex !important;
    }
    .list-header-action{
        height: 66px;
    }
    .list-header-action .flex-row{
        height: 100%;
    }
    .icon-menu{
        display: block !important;
    }
}

@media (max-width: 578px){
    .list-header-action .flex-row .phone 
    , .history{
        display: none !important;  
    }
}

@media (max-width: 1255px){
    .count-cart{
        top: 4px;
    }
}