.grid-temp-pagination {
    display: grid;
    grid-template-columns: repeat(18, minmax(0, 1fr));
    width: 100%;
}

.frame-pagination {
    width: 100%;
    overflow-x: auto;
    border-radius: 4px;
}

.grid-temp-pagination.header {
    border-bottom: 1px solid #e9eaeb;
}

.grid-temp-item {
    grid-column: span 2 / span 2;
    padding: 12px;
}

.grid-temp-item.col-10 {
    grid-column: span 10 / span 10;
}

.box-pagination {
    min-width: 893px;
}

.image-product {
    width: 60px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    height: 60px;
    object-fit: contain;
}

.bdr {
    border-right: 1px solid #ddd;
}


/* .table-row-1{
    background-color: #f1f1f1;
}

.table-row-2{
    background-color: #e7e7e7;
} */
