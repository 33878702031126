
.footer-bg{
    padding: 12px;
    /* max-width: 2200px; */
    /* margin-top: 97px; */
    margin: 0 auto;
    width: 100%;
    background-color: #101628;
}

.input-email{
    width: 100%;
    padding: 0 12px;
    height: 36px;
    background-color: rgba(43, 47, 61, 0.2);
    border: none;
    color: white;
}

.input-email:focus{
    outline: none;
}

.action-footer{
    height: 36px;
    background-color: #fab55a;
}

.logo-footer{
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.frame-form-footer{
    width: 600px;
    max-width: 100%;
}

.footer {
    padding: 30px 0px;
    font-family: "Play", sans-serif;
    text-align: center;
}

.footer .row {
    width: 100%;
    margin: 1% 0%;
    padding: 0.6% 0%;
    color: gray;
    font-size: 0.8em;
}

.footer .row a {
    text-decoration: none;
    color: gray;
    transition: 0.5s;
}

.footer .row a:hover {
    color: white;
}

.footer .row ul {
    width: 100%;
}

.footer .row ul li {
    display: inline-block;
    margin: 0px 30px;
}

.footer .row a i {
    font-size: 2em;
    margin: 0% 1%;
}

@media (max-width: 720px) {
    .footer {
        text-align: left;
        padding: 5%;
    }
    .footer .row ul li {
        display: block;
        margin: 10px 0px;
        text-align: left;
    }
    .footer .row a i {
        margin: 0% 3%;
    }
}
