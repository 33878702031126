.container-detail {
    max-width: 1200px;
    margin: 0 auto;
    padding: 32px;
}

.item-repon {
    width: 100%;
    /* min-width: fit-content; */
}

.slide-item{
    width: fit-content !important;
}

.detail-image {
    /* border-radius: 6px; */
    max-width: 100%;
    margin: 0 auto;
    /* overflow: hidden; */
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15); */
}

.frame-slide-detail{
    max-width: 100%;
    margin: 0 auto;
    width: 520px;
}

.frame-img-modal{
    width: 600px;
    height: auto;
    overflow: hidden;
    max-width: 100%;
}

.frame-img-modal img{
    width: 100%;
    height: auto;
    object-fit: contain;
}

.rectangle {
    margin-top: 16px;
    border-radius: 4px;
    padding: 12px;
    min-height: 100px;
    background-color: #f1f1f1;
    position: relative;
}

/* .slick-slide div .video-zoom{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
} */

.video-zoom{
    margin: 0 auto;
}

.limit{
    width: 510px;
    max-width: 100%;
}

.triangle {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #f1f1f1; /* Màu và kích thước của tam giác */
    position: absolute;
    top: -12px;
    left: 16px;
}

.detail-image .image {
    width: 100%;
    border-radius: 6px;
    max-width: 510px;
    object-fit: contain;
}

.count {
    width: 200px;
    border-radius: 100px;
    overflow: hidden;
    height: 40px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.action-count {
    height: 100%;
    cursor: pointer;
    width: 100%;
}

.buy-action {
    height: 46px;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
}

.buy-action:hover {
    transition: ease-in-out 0.2s;
    transform: scale(1.02);
    position: relative;
    z-index: 1;
}

.buy-action.buy {
    background-color: rgb(33, 85, 54);
    color: white;
}

.buy-action.add-to-cart {
    background-color: #c57819;
    color: white;
}

.action-count.left {
    border-right: 1px solid #dddddd4b;
}

.action-count.right {
    border-left: 1px solid #dddddd4b;
}

.count-item {
    width: 160px;
}

.shield {
    border-radius: 6px;
    box-shadow: 0px 0px 5px rgba(148, 148, 148, 0.15);
    height: 52px;
    padding: 12px;
}

.grid-item-content {
    width: 100%;
    padding: 8px;
    height: 60px;
    border-right: 1px solid #ddd;
}

.grid-item-content.no-border {
    border: none;
}

.logo-img {
    object-fit: contain;
}

.info-item {
    height: fit-content;
    padding: 12px;
}

.infomation {
    border-radius: 4px;
    height: auto;
    overflow: hidden;
}

.header-inf {
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.box-inf {
    padding: 12px;
}

.frame-detail-img{
    position: relative;
    width: 100%;
    height: 360px;
}

.frame-detail-img .image.detail{
    position: absolute;
    bottom: 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.comment-action {
    width: 100%;
    height: 48px;
    color: white;
    margin-left: auto;
    border-radius: 4px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    background-color: #d88621;
}

.box-review{
    display: grid;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #ddd;
    width: 100%;
    grid-template-columns: repeat(5, minmax(0, 1fr));
}

.star{
    width: 26px;
}

.box-review .review-item{
    padding: 12px;
}

.box-review .review-item.center{
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    grid-column: span 3 / span 3;
}


@media (max-width: 1045px){
    .section-detail{
        gap: 12px;
    }
}

@media (max-width: 1045px){
    .grid-col-1{
        gap: 12px !important;
    }
}

@media (max-width: 982px){
    .grid-col-1{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 989px){
    .item-repon{
        width: 480px;
    }
}

@media (max-width: 908px){
    .item-repon{
        gap: 12px !important;
        width: 100%;
    }
    .section-detail{
        flex-wrap: wrap;
    }
    .detail-image.limit{
        width: 100% !important;
    }
    .detail-image .image{
        max-width: 100% !important;
    }
    .slick-prev{
        left: -5px;
        z-index: 2;
    }
    .slick-next{
        right: -5px;
        z-index: 2;
    }

    .default-container{
        margin-top: 70px;
    }

    .grid-col-1{
        grid-template-columns: 1fr 1fr !important;
    }
}

@media (max-width: 1072px){
    .grid-col-1{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1104px){
    .grid-col-1{
        gap: 12px;
    }

    .section-detail{
        gap: 12px !important;
    }
}

@media (max-width: 594px){
    .default-container{
        margin-top: 0 !important;
    }
}

@media (max-width: 700px){
    .default-container{
        margin-top: 90px;
    }
}

@media (max-width: 928px){
    .box-review .review-item{
        grid-column: span 5 / span 5;
    }
    .box-review .review-item{
        grid-column: span 5 / span 5 !important;
    }
}

@media (max-width: 1118px){
    .section-detail-item{
        flex-wrap: wrap;
    }

    .item-repon{
        margin: 0 auto;
    }
}

@media (max-width: 1067px){
    .grid-col-4{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 518px){
    .container-detail{
        padding: 24px 0 24px 0;
    }
}

@media (max-width: 522px){
    .button-buy{
        position: fixed;
        display: grid;
        bottom: 0;
        z-index: 1;
        gap: 0 !important;
        padding: 0;
        margin-right: auto;
    }

    .buy-action{
        border-radius: 0;
    }
}

@media (min-width: 377px){
    .grid-col-1{
        grid-template-columns: 1fr 1fr 100px !important;
    }
    .buy-action{
        flex-wrap: wrap;
        gap: 2px;
        padding: 4px;
    }
}

@media (min-width: 523px){
    .grid-col-1{
        grid-template-columns: 1fr 1fr !important;
    }
}

@media (max-width: 376px){
    .buy-action{
        padding: 0 4px;
    }
    .buy-action .text-15{
        font-size: 12px;
    }
    .grid-col-1{
        grid-template-columns: max-content 1fr 60px !important;
    }
    
    .buy-action.buy{
        padding: 4px 24px;
    }
}

@media (max-width: 296px){
    .buy-action.buy{
        padding: 4px 8px;
    }
}

@media (max-width: 358px){
    .buy-action {
        flex-wrap: wrap;
        gap: 2px;
        padding: 4px;
    }
}


@media (max-width: 484px) {
    .slide-detail{
        gap: 0px !important;
      }

      .slide-detail .slick-next {
        right: 10px;
      }

        .slide-detail .slick-prev {
            left: 10px;
        }

      .splider-img {
        width: 70px;
        height: 70px;
      }

      .buy-action{
        height: 60px;
      }
      .add-to-cart {
        gap: 2px;
        flex-direction: column;
      }

      .add-to-cart h1 {
        font-size: 11px;
        margin-bottom: 10px;
      }
}
    