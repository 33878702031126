
.slider-home{
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}

.img-slider{
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    /* position: relative; */
    object-fit: contain;
}


.grid-slider-home{
    display: grid;
    grid-template-columns: 310px 1fr;
}

.splider-img{
    width: 90px;
    height: 90px;
    object-fit: contain;
}

.pointer{
    cursor: pointer;
}

.slider-action{
    width: 40px;
    height: 40px;
    border-radius: 9999px;
    z-index: 3;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: white;
    position: absolute;
    box-shadow: rgba(95, 100, 105, 0.2) 0px 8px 24px;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(180, 180, 180, 0.582);
}

.slider-action.next{
    right: -2.5rem;
}

.section.prev{
    left: 0;
}

.list-fill{
    /* height: 80px; */
    width: 100%;
    overflow: hidden;
    margin-top: 24px;
    border-radius: 8px;
}

.grid-template-home{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}

.bd{
    border: 1px solid #7c7c7c67;
}

.frame-banner{
    border-radius: 6px;
  }

  .frame-banner .banner{
    height: auto;
    width: 100%;
    object-fit: contain;
    border-radius: 6px;
  }

.grid-template-home .col-3{
    grid-column: span 3 / span 3;
}

.product{
    position: relative;
}

.devvn_label_product {
    position: absolute;
    z-index: 3;
    top: 7px;
    left: -5px;
}

.devvn_label_product span.sale {
    background-image: url("/public/media/sale.svg");
    width: 93px;
}

.devvn_label_product span.new {
    background-image: url("/public/media/new.svg");
    width: 103px;
    color: #000;
}

.devvn_label_product span {
    display: inline-flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding-bottom: 7px;
    font-size: 13px;
    color: #fff;
    font-weight: 550;
}

@media (max-width: 946px){
    .grid-template-home{
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
}

@media (max-width: 712px){
    .grid-template-home{
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}