.cart-left{
    max-width: 800px;
}

.form-add-inf{
    min-width: 600px !important;
    max-width: 100%;
}

.container-payment{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(14, minmax(0,1fr));
    gap: 24px;
}

.label-dark .ant-form-item-row .ant-form-item-label label{
    color: #969696;
}

.input-dark .ant-input-wrapper .ant-input-affix-wrapper input::placeholder{
    color: #969696;
}

.input-dark .ant-input-wrapper .ant-input-group-addon button{
    color: #969696;
}

.input-dark{
    background-color: #0e1322;
    border: 1px solid #2b3a68;
    color: white;
}

.input-dark::placeholder{
    color: #969696;
}

.payment-left{
    grid-column: span 9 / span 9;
    border-radius: 4px;
    height: fit-content;
}

.payment-right.checkout{
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #fab55a;
}

.button-payment{
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    display: flex;
    transition: ease-in-out 0.2s;
    color: white;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #193f21;
}

.button-payment:hover{
    transform: scale(1.02);
}

.box-center{
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.payment-right{
    grid-column: span 5 / span 5;
}

.paymment-item-grid{
    display: grid;
    height: fit-content;
    grid-template-columns: repeat(14, minmax(0, 1fr));
    gap: 8px;
    padding: 12px;
}

.paymment-item-grid .grid-item{
    grid-column: span 3 / span 3;
}

.paymment-item-grid .grid-item.big-col{
    grid-column: span 8 / span 8;
}

.paymment-item-grid{
    min-width: 465px;
}

@media (max-width: 1000px){
    .payment-left {
        /* overflow-x: auto; */
        grid-column: span 14 / span 14;
    }
    .payment-right {
        grid-column: span 14 / span 14;
    }
}