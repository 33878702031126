@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat:wght@300&family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=PT+Sans:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Tilt+Neon&display=swap");
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  /* overflow-y: auto; */
}
body::-webkit-scrollbar {
  display: none;
}
* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
span {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
}


ul {
  margin: 0;
  padding: 0;
}

.mg-auto {
  margin: 0 auto;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* .item.slider.mini {
  width: 254px !important;
} */

.min-slider.detail .slick-list .slick-track .slick-slide{
  width: fit-content !important;
}

@media (min-width: 1100px){
  .min-slider.normal .slick-list .slick-track .slick-slide{
    width: fit-content !important;
  }
  .min-slider.normal .slick-list .slick-track{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 736px){
  .min-slider.normal.col-2 .slick-list .slick-track .slick-slide{
    width: fit-content !important;
  }
  .min-slider.normal.col-2 .slick-list .slick-track{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* .min-slider .slick-list .slick-track{
  display: flex;
  align-items: center;
  justify-content: center;
} */

.w-100 .ant-row {
  width: 96%;
}

.goilai {
  width: 100%;
  height: 40px;
  border: 1px solid #dddddd6e !important;
  border-radius: 4px;
  border: none;
  color: black;
  padding: 0 12px;
  background-color: #d3d3dd;
}

.goilai:focus {
  background-color: #d3d3dd;
  outline: none;
}

.woocommerce-review__verified {
  color: #52b858;
  font-size: 13px;
}

.pdt-12 {
  padding-top: 12px;
}

.pdb-12 {
  padding-bottom: 12px;
}

.item {
  height: 380px;
}

/* .slick-track {
  padding: 12px 0;
} */

.item-frame-image{
  width: 100%;
  overflow: hidden;
  height: 260px;
  position: relative;
}

.img-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.bdr-t-4{
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.overlay-image{
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s ease;
}

.overlay-image:hover{
  opacity: 1;
}

.white-color {
  color: white;
}

.pd-12 {
  padding: 12px;
}

.mgt-5 {
  margin-top: 5px;
}

.text-link {
  color: rgb(42, 74, 110);
}

.mgt-12 {
  margin-top: 12px;
}

.mgt-24 {
  margin-top: 12px;
}

.pdt-24 {
  padding-top: 24px;
}

.mgl-auto {
  margin-left: auto;
}

.max-width {
  width: 100% !important;
}

.flex-row {
  display: flex !important;
  /* width: 100%; */
  flex-direction: row;
}

.flex-100 {
  width: 100%;
}

.info-item {
  min-height: fit-content;
}

/* .slider-fix.max-width .slick-list .slick-track {
  width: 100% !important;
} */

/* .slider-fix .slick-list .slick-track .slick-slide {
  width: fit-content !important;
} */

.sort-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 32px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

/* .slider-fix .slick-list .slick-track {
  display: flex;
  gap: 14px;
} */

.box-border-bottom {
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
}

.info-item.col-2 {
  background-color: #f0f0f0;
}

.info-item.col-2.dark {
  background-color: #0d1222;
}

.info-item.col-1.dark {
  background-color: #080b14;
}

.bd-b {
  border-bottom: 1px solid #e9eaeb;
}

.info-item.col-1 {
  background-color: #e7e7e7;
}

.component-container {
  width: 100%;
  /* max-width: 1100px; */
  margin: 0 auto;
}

.default-container {
  margin-top: 108px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-8 {
  gap: 8px;
}

.flex-between {
  justify-content: space-between;
}

.text-13 {
  font-size: 13px;
}

.text-14 {
  font-size: 14px;
}

.text-15 {
  font-size: 15px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-30 {
  font-size: 30px;
}

.f-500 {
  font-weight: 500;
}

.f-400 {
  font-weight: 400;
}

.f-600 {
  font-weight: 600;
}

.color-b {
  color: black;
}

.color-w {
  color: white !important;
}

.mgr-24 {
  margin-right: 24px;
}

.flex-row-center {
  align-items: center;
}

.bdr-999 {
  border-radius: 9999px;
}

.gap-4 {
  gap: 4px;
}

.gap-12 {
  gap: 12px;
}

.gap-24 {
  gap: 24px;
}

.min-fit {
  min-width: fit-content;
  word-wrap: nowrap;
  white-space: nowrap;
}

.white-space{
  white-space: nowrap;
}

.percent-count {
  width: 40px;
}

.ant-dropdown-menu {
  background-color: #272d3d !important;
}

.ant-dropdown-arrow::before {
  background-color: #272d3d !important;
}

.ant-dropdown-menu-item {
  padding: 6px !important;
  color: white;
}

.text-hover {
  transition: ease-in-out 0.2s;
  cursor: pointer;
}

.text-12 {
  font-size: 12px;
}

.bold{
  font-weight: bold;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.max-w-desktop{
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.default-container.blog{
  margin-top: 90px !important;
}

.grid-auto-fill {
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(245px, 1fr)); 
  grid-gap: 1em;
}

.grid-auto-fill div{
  width: 100%;
}

.internal-error-page.dark{
 background-color: #06080F;
}

.internal-error-page.light{
  background-color: white;
 }

.mg-header {
  margin-top: 96px;
}

.success-color {
  color: rgb(49, 167, 49);
}

.error-color {
  color: rgb(177, 54, 60);
}

.color-y {
  color: #fab55a;
}

.percent-row {
  display: grid;
  grid-template-columns: repeat(100, minmax(0, 1fr));
  background-color: #ddd;
  border-radius: 3px;
  overflow: hidden;
  height: 20px;
  width: 70%;
}

.percent-row .percent {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #fab55a;
}

.bd-w {
  border: 1px solid rgba(255, 255, 255, 0.315);
}

.box-shadow {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.text-hover:hover {
  color: #fab55a !important;
}

.text-38 {
  font-size: 38px;
}

.max-100 {
  width: 100%;
}

.input {
  width: 100%;
  background-color: transparent;
  border: none;
  min-height: 28px;
}

.img-blog{
  width: 600px;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}

.box-shadow-w{
  box-shadow: 0px 0px 5px rgb(253 253 253 / 15%);
}

.align-center{
  align-items: center;
}

.c-icon{
  width: 24px;
  height: 24px;
  background-color: #FEF6EC;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-icon > *{
  color: #FCCE92;
}

.mgb-0 {
  margin-bottom: 0;
}

.dark .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon{
  color: white !important;
}

.dark .ant-collapse-item .ant-collapse-content{
  overflow: hidden;
}

.light .ant-collapse-item .ant-collapse-content{
  overflow: hidden;
}

.pdr-12{
  padding-right: 12px;
}

.dark .ant-pagination .ant-pagination-prev .ant-pagination-item-link{
  color: white;
}

.dark .ant-pagination .ant-pagination-item{
  background-color: #101628;
}

.dark .ant-pagination .ant-pagination-item:hover{
  background-color: #354063;
}

.dark .ant-pagination .ant-pagination-next .ant-pagination-item-link{
  color: white;
}

.dark .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
  background-color: #06080F;
  overflow: hidden;
  color: rgb(110, 107, 107);
}

.dm-text{
  cursor: pointer;
}

.dm-text:hover{
  color: rgb(39, 82, 131)
}

.light .ant-collapse-item .ant-collapse-content .ant-collapse-content-box{
  background-color: #f3f3f3;
  overflow: hidden;
  color: rgb(110, 107, 107);
}

.light .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon{
  color: black !important;
}

.mgb-12 {
  margin-bottom: 12px;
}

.mgb-24 {
  margin-bottom: 24px;
}

.goilai:hover {
  background-color: #d3d3dd;
}

.input:focus {
  outline: none;
}

.pdb-4 {
  padding: 4px;
}

.pdt-4 {
  padding: 4px;
}

.pdtb-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.pdlr-6 {
  padding-right: 6px;
  padding-left: 6px;
}

.bdr-4 {
  border-radius: 4px;
  overflow: hidden;
}

.pd-24 {
  padding: 24px;
}

.pd-4 {
  padding: 4px;
}

.pd-32 {
  padding: 32px;
}

.flex-col {
  display: flex !important;
  flex-direction: column;
  /* width: 100%; */
}

.text-default {
  color: black;
}

.flex-col-center {
  justify-content: center;
}

.w-100 {
  width: 100%;
}

.pdl-12 {
  padding-left: 12px;
}

.text-blur {
  color: var(--text-blur);
}

.no-max-width {
  max-width: 100% !important;
}

.slider-action.next.minus {
  right: -10px !important;
}

.slider-action.prev.minus {
  left: 28px !important;
}

.img-slider.minus {
  border-radius: 0 !important;
}

.grid-col-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.grid-col-4 {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.line-row {
  width: 1px;
  height: 100%;
  background-color: #00000036;
}

.status-text {
  color: rgb(33, 85, 54);
}

.flex-evenly {
  justify-content: space-evenly;
}

.action {
  width: fit-content;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  background-color: #3554d1;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  gap: 8px;
}

.left-auto {
  margin-left: auto;
}

.flex-start {
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}

.flex-end {
  align-items: flex-end;
  justify-content: flex-end;
}

.status-action {
  width: fit-content;
  padding: 8px 12px;
  border-radius: 100px;
}

.label-color {
  color: #c57819;
}

.relative {
  position: relative;
}

.gap-32 {
  gap: 32px;
}

.title {
  margin-bottom: 12px;
}

.item.dark-mode {
  background: linear-gradient(180deg, #040b1f 0%, #1a2136 100%);
  box-shadow: 0px 0px 5px rgba(161, 160, 160, 0.15) !important;
  border-radius: 4px;
  display: flex !important;
  flex-direction: column;
  gap: 12px;
}

.inf-item {
  padding: 12px;
}

.hover-scale {
  position: relative;
  transition: ease-in-out 0.2s;
}

.hover-scale:hover {
  z-index: 1;
  transform: scale(1.02);
}

.pointer {
  cursor: pointer;
}

.line-center {
  position: absolute;
  z-index: 1;
  height: 1px;
  /* top: 40%; */
  width: 100%;
  background-color: rgba(0, 0, 0, 0.281);
}

.text-center {
  text-align: center;
}

.form-design {
  margin-top: 18px;
  width: fit-content;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid #ddd;
}

.form-design .ant-row .ant-col .ant-form-item {
  margin-bottom: 0;
}

.class-dark {
  color: white !important;
}

.dark .ant-rate-star.ant-rate-star-zero div .ant-rate-star-second {
  color: rgba(255, 255, 255, 0.205) !important;
}

.class-dark .ant-select-selector {
  background-color: #272d3d !important;
  color: white;
  border: none !important;
}

.class-dark .ant-select-selector .ant-select-selection-placeholder {
  color: white !important;
}

.no-bg {
  background: none;
}

.container-main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px;
}
.no-border::after {
  border: none !important;
  width: 0 !important;
}

.fit-content{
  width: fit-content !important;
}

.h-100{
  height: 100%;
}

.item.slider{
  margin: 4px 0;
  display: flex;
  width: 320px;
  max-width: 100%;
}

.slick-next:before, .slick-prev:before{
  color: #9e9c9c;
}

.frame-slider.dark{
  background-color: rgb(9 12 22);
}

.frame-slider.light{
  background-color: #f6f6f7;
}

.frame-slider {
  width: 100%;
  border-radius: 4px;
  max-width: 600px;
  overflow: hidden;
  padding: 12px;
  position: relative;
  overflow: inherit;
}

.slick-initialized .slick-slide{
  width: fit-content;
}

/* .slick-initialized .slick-slide{
  display: flex !important;
    align-items: center;
    justify-content: center;
} */

.no-border::before {
  border: none !important;
  width: 0 !important;
}

.min-h-input {
  min-height: 40px;
  border-radius: 4px;
}

.slick-arrow{
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  border-radius: 9999px;
}
.slick-slide > div {
  margin: 0 12px;
}

.slider-home .slick-slide > div {
  margin: 0;
}


.frame-slider .slick-slide > div {
  margin: 0 6px;
}

.detail-image .slick-slider .slick-list .slick-track .slick-slide > div{
  margin: 0;
}

.slick-list {
  margin: 0 -6px;
}

.comment-img {
  cursor: pointer;
  width: 50px;
  height: 50px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  object-fit: contain;
}

.avatar-img {
  width: 60px;
  height: 60px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  object-fit: contain;
}

@media (max-width: 416px) {
  .text-15 {
    font-size: 13px;
  }
  .text-13 {
    font-size: 11px;
  }
}

@media (max-width: 594px) {

  .mgb-24{
    margin-bottom: 12px;
  }

  .gap-24{
    gap: 12px;
  }
}

@media (max-width: 632px) {
  .frame-slider {
    /* max-width: 524px; */
  }
}

@media (max-width: 564px) {
  .frame-slider {
    /* max-width: 405px; */
  }
}

@media (max-width: 430px) {
  .frame-slider {
    /* max-width: 310px; */
  }
}

.history-in-dropdown {
  display: none;
}

@media (max-width: 594px) {

  .main{
    padding: 0;
  }

  a .logo {
    display: none;
  }

  .default-container {
    margin-top: 0;
  }

  .frame-banner {
    height: auto;
  }

  .frame-banner .banner {
    border-radius: 6px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .limit {
    width: 100% !important;
  }

  .detail-image .image {
    max-width: none !important;
    border-radius: 0 !important;
  }

  .main {
    padding: 0 !important;
  }

  .header-class {
    background-color: #10162827 !important;
  }

  .phone {
    display: none !important;
  }

  .history {
    display: none !important;
  }

  .history-in-dropdown {
    display: flex !important;
  }

  .header-class {
    padding: 0 12px !important;
  }

  .products-custom {
    margin-top: 10px !important;
    padding: 10px;
  }
  
  .products-custom .product_row {
    margin-top: 10px !important;
  }
}

@media (max-width: 825px) {
  .text-30 {
    font-size: 24px;
  }
}

@media (max-width: 570px) {
  .text-30 {
    font-size: 20px;
  }
}

@media (max-width: 336px) {
  .frame-slider {
    /* max-width: 216px; */
  }
}

@media (max-width: 484px) {
  
  .mg-header {
    margin-top: 66px;
  }

  .frame-slider {
    padding: 4px;
  }

  .frame-slider .slick-slide > div {
    margin: 0 4px;
  }

  .shield {
    width: 100%;
  }
  
  .item-frame-image {
    height: 200px;
  }

  .footer-item {
    flex-wrap: wrap !important;
    justify-content: center;
  }
}

@media (max-width: 1172px) {
  .frame-banner {
    display: none;
  }
  .grid-template-home .col-3 {
    grid-column: span 4 / span 4 !important;
  }
}

@media (max-width: 1262px) {
  .grid-template-home {
    gap: 8px !important;
  }
}

@media (max-width: 1124px) {
  .main {
    padding: 32px 12px;
  }
}

:root {
  --main-major: #55b9a9;
  --bg-button: #caab72;
  --bg-contrast: #2a2d3e;
  --bg-main: #ffffff;
  --main-bg: white;
  --text-color: white;
  --text-blur: #a9aaac;
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 2rem;
  --spacing-4: 8px;
  --spacing-22: 44px;
  --rounded-sm: 6px;
  --fs-2lg: 18px;
  --fs-lg: 16px;
  --fs-2xl: 20px;
  --fs-4xl: 24px;
}
