.chat-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 9999px;
    background-color: #101628;
    z-index: 10;
    color: white;
    transition: ease-in-out 0.2s;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
}

.dot-message{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(206, 108, 108);
    color: white;
    border-radius: 9999px;
    position: absolute;
    top: -0.5rem;
    right: 0.5rem;
    z-index: 10;
}

.box-chat{
    width: 320px;
    /* padding: 12px; */
}

.box-message{
    padding: 6px;
    border-radius: 6px;
}

.box-message.gray{
    background-color: rgb(107, 107, 107);
    color: white;
}

.box-message.you{
    color: white;
    background-color: #0084ff ;
}

.box-chat-content{
    display: flex;
    flex-direction: column;
    height: 350px;
    overflow-y: auto;
    padding: 12px;
    background-color: #161e36;
}

.box-chat-content::-webkit-scrollbar{
    width: 4px;
}

.input-send-message{
    border-radius: 4px;
    width: 100%;
    border: none;
    background-color: #101628;
    color: white;
}

.input-send-message:focus{
    outline: none;
}

.box-chat-content::-webkit-scrollbar-thumb{
    background-color: rgb(160, 159, 159);
}
.ant-popover-inner{
    padding: 0 !important;
    background-color: #101628 !important;
}

.ant-popover-title{
    padding: 12px;
    background-color: #101628;
    margin-bottom: 0 !important;
    color: white !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    overflow: hidden;
}

.ant-popover-arrow::before{
    background-color: #101628 !important;
}

.white-color .ant-empty-description{
    color: white;
}

@media (max-width: 522px){
    .chat-container.by-detail{
        bottom: 0;
        width: 100px;
        right: 0;
        border-radius: 0;
        height: 46px;
    }
}

@media (max-width: 452px){
    .chat-container.by-detail{
        width: 60px;
    }



    .chat-container  {
      height: 60px !important;
    }
}

@media (min-width: 523px){
    .chat-container.by-detail{
        width: 60px !important;
    }
}

@media (min-width: 377px){
    .chat-container.by-detail{
        width: 100px;
    }
}