.success-container{
    width: 600px;
    padding: 12px;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0px 0px 5px rgba(155, 155, 155, 0.15);
}

.icon-success{
    font-size: 56px;
    color: rgb(27, 85, 27);
}